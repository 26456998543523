<template>
  <header>
    <nav id="navigation" class="navbar navbar-expand-lg navbar-light">
      <div v-if="isAuthenticated" class="logo">
        <div id="logoContainer">
          <router-link :to="{ name: 'Home' }"><img id="headerLogo" :src="tenantLogo" alt="Logo" /></router-link>
        </div>
      </div>
      <template v-if="!loading">
        <button id="navOpener" v-if="isAuthenticated" class="navbar-toggler order-2 order-lg-1" aria-controls="mainMenu" aria-expanded="false" @click="toggleNav">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div id="linksContainer" ref="linksContainerRef" v-if="navOpened" class="collapse navbar-collapse order-3 order-lg-2" :class="{ show: navOpened }">
          <ul id="textLinks" v-if="isAuthenticated">
            <router-link :to="{ name: 'Home' }" custom v-slot="{ href, navigate, isExactActive }">
              <li :class="{ 'router-link-exact-active': isExactActive }">
                <div class="navItemButtons">
                  <a :href="href" @click="navigate">
                    <span>Johdanto</span>
                  </a>
                </div>
              </li>
            </router-link>
            <!-- If only one training -->
            <template v-if="trainings">
              <router-link v-if="trainings.length === 1" :to="{ name: 'Training', params: { trainingId: trainings[0].id } }" custom v-slot="{ href, navigate, isActive, isExactActive }">
                <li :class="{ 'router-link-active': isActive, 'router-link-exact-active': isExactActive }">
                  <div class="navItemButtons">
                    <a :href="href" @click="navigate"><span>Koulutus</span></a>
                  </div>
                </li>
              </router-link>
              <!-- If multiple trainings -->
              <router-link v-else :to="{ name: 'Trainings' }" custom v-slot="{ href, navigate, isActive, isExactActive }">
                <li :class="{ 'router-link-active': isActive || $route.meta.isInTrainingNav, 'router-link-exact-active': isExactActive }">
                  <div class="navItemButtons">
                    <a :href="href" @click="navigate">
                      <span>Koulutukset</span>
                    </a>
                    <div v-if="trainings" class="submenuButtonContainer">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="arrowIcon bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                  </div>
                  <ul
                    :ref="
                      (el) => {
                        if (el) submenuRefs.push(el);
                      }
                    "
                    class="sub-menu"
                  >
                    <router-link v-for="training in trainings" :to="{ name: 'Training', params: { trainingId: training.id } }" custom v-slot="{ href, navigate, isActive, isExactActive }" :key="training.id">
                      <li :class="{ 'router-link-active': isActive || $route.params.trainingId === training.id, 'router-link-exact-active': isExactActive }">
                        <div class="navItemButtons">
                          <a :href="href" @click="navigate"
                            ><span>{{ training.name }}</span></a
                          >
                        </div>
                      </li>
                    </router-link>
                  </ul>
                </li>
              </router-link>
            </template>
            <router-link :to="{ name: 'Help' }" custom v-slot="{ href, navigate, isActive, isExactActive }">
              <li :class="{ 'router-link-active': isActive, 'router-link-exact-active': isExactActive }">
                <div class="navItemButtons">
                  <a :href="href" @click="navigate"><span>Ohje</span></a>
                </div>
              </li>
            </router-link>
            <router-link v-if="environmentMode === envMode.DEVELOPMENT" :to="{ name: 'TaskGallery' }" custom v-slot="{ href, navigate, isActive, isExactActive }">
              <li :class="{ 'router-link-active': isActive, 'router-link-exact-active': isExactActive }">
                <div class="navItemButtons">
                  <a :href="href" @click="navigate"><span>Tehtävägalleria</span></a>
                </div>
              </li>
            </router-link>
          </ul>
        </div>
        <ul id="iconLinks" class="order-1 order-lg-2" v-if="isAuthenticated">
          <li>
            <router-link :to="{ name: 'OwnInformation' }">
              <svg width="32px" height="32px" viewBox="0 0 16 16" class="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Logout' }">
              <svg width="32px" height="32px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z" />
                <path fill-rule="evenodd" d="M7.5 8V1h1v7h-1z" />
              </svg>
            </router-link>
          </li>
        </ul>
      </template>
    </nav>
  </header>
</template>

<script>
import { computed, ref, watch, onBeforeMount, onBeforeUpdate } from "vue";

import { useRoute } from "vue-router";
import { Store, AppConfig } from "@moods/taitaen-core";
//import { tenantLogo } from "@src/tenantData";
import tenantData from "@src/tenantData";
import envMode from '../../envMode';

export default {
  name: "Header",
  setup() {
    const loading = ref(false);
    const changed = ref(false);
    const navOpened = ref(true);
    const linksContainerRef = ref(null);
    const submenuRefs = ref([]);
    //const trainings = ref(null);
    const maxWidth = 991.98;
    const trainings = ref(null);

    const route = useRoute();

    watch(
      () => route.fullPath,
      () => {
        if (window.innerWidth <= maxWidth) {
          navOpened.value = false;
        }
      }
    );

    onBeforeUpdate(() => {
      submenuRefs.value = [];
    });

    onBeforeMount(() => {
      onResize();
      window.addEventListener("resize", onResize);
    });

    const isAuthenticated = ref(Store.getter("User/isAuthenticated"));
    if (isAuthenticated.value) {
      getTrainings();
    }

    Store.subscribe("User/token", (newValue) => {
      isAuthenticated.value = newValue;
      if (newValue) {
        getTrainings();
      } else {
        trainings.value = null;
        //trainingsState.value = null;
      }
    });

    function getTrainings() {
      loading.value = true;
      return Store.dispatch("Trainings/getTrainings").then((res) => {
        loading.value = false;
        trainings.value = res.data;
      });
    }

    function onResize() {
      if (window.innerWidth >= maxWidth) {
        navOpened.value = true;
        changed.value = false;
      } else {
        if (!changed.value) {
          changed.value = true;
          navOpened.value = false;
        }
      }
    }

    function toggleNav() {
      navOpened.value = !navOpened.value;
    }

    return {
      navOpened: computed(() => navOpened.value),
      trainings: computed(() => trainings.value),
      //trainings: computed(() => trainingsState.value),
      toggleNav,
      linksContainerRef,
      submenuRefs,
      loading,
      isAuthenticated: computed(() => isAuthenticated.value),
      environmentMode: AppConfig.get().environmentMode,
      envMode,

      tenantLogo: tenantData.tenantLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

#headerLogo {
  width: 150px;
  max-width: 100%;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.fade-enter-active {
  animation: fade-in 0.1s;
}
.fade-leave-active {
  animation: fade-in 0.1s reverse;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

a {
  text-decoration: none;
  // &hover {
  //   color: initial;
  // }
}

.submenuButtonContainer {
  display: flex;
  align-items: center;
}

.navItemButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-menu {
  transition: opacity 0.3s ease;

  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-color-first);

  li {
    > .navItemButtons:hover,
    &.router-link-exact-active > .navItemButtons {
      background-color: var(--subnav-link-item-bg-color-active);
      color: var(--theme-color-second);
    }

    a {
      padding-top: 4px;
      padding-bottom: 4px;
      width: 100%;
    }
  }

  .navItemButtons {
    background-color: var(--theme-color-first);
  }

  a {
    display: flex;
    color: var(--theme-color-second);
  }
}

@media only screen and (min-width: $mdMinWidth) {
  .sub-menu {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    min-width: 150px;
    max-width: 280px;
    z-index: 20;
  }
}

ul {
  > li {
    &:hover > .sub-menu {
      visibility: visible;
      opacity: 1;
    }
  }
}

header {
  ul {
    margin-bottom: 0;
    padding-left: 0;

    li {
      display: inline-block;

      .icon {
        font-size: 30px;
      }
      #logout {
        transform: rotate(270deg);
      }
    }
  }
}
#textLinks {
  margin-left: auto;

  .arrowIcon {
    margin-left: 5px;
    // color: var(--theme-color-second);
  }
  a {
    display: flex;
    align-items: center;
  }
  li.router-link-active > div > a > span,
  li.router-link-exact-active > div > a > span {
    font-weight: bold;
  }
  // li.router-link-active > div > a > span {
  //   color: black;
  // }
}
#navContainer {
  display: flex;
}
#iconLinks {
  a {
    margin-left: 10px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  margin-right: 15px;
}
#linksContainer {
  align-items: center;

}

@media screen and (max-width: $mdMaxWidth) {
  #linksContainer {
    background-color: var(--theme-color-first);

    a {
      color: var(--theme-color-second);
    }
  }
  #textLinks {
    .arrowIcon {
      color: var(--theme-color-second);
    }
  }
}

//@media only screen and (min-width: $mdMinWidth) {
/*
    @media only screen and (min-width: $mdMaxWidth) {
        #mobileNavButton {
            display: none;
        }
        #linksContainer {
            display: flex;
        }
    }
    */

#navOpener {
  background-color: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  /*margin-left: auto;*/

  &:focus {
    outline: 0;
  }

  span {
    border: 2px solid var(--navbar-mobile-color-border);
    //background-color: var(--theme-color-second);
    height: 2px;
    width: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 2px;
    display: block;
  }
}
@media only screen and (min-width: $mdMinWidth) {
  .navItemButtons {
    padding-left: 20px;
    padding-right: 20px;
  }
  #navOpener {
    display: none;
  }
}
@media only screen and (max-width: $mdMaxWidth) {
  .navItemButtons {
    padding-left: 30px;
    padding-right: 30px;
  }
  .logo {
    margin-right: auto;
  }
  #textLinks {
    display: flex;
    flex-direction: column;
    margin-left: 0;

    li {
      display: inline-block;
      border-top: 1px solid var(--theme-color-second);

      > .navItemButtons:hover,
      &.router-link-exact-active > .navItemButtons {
        background-color: var(--subnav-link-item-bg-color-active);
        color: var(--theme-color-second);
      }

      a {
        display: block;
        width: 100%;
        /*
                    padding-top: 5px;
                    padding-bottom: 5px;
                    */
        margin-left: 0;
        margin-right: 0;

        // span {
        //     margin-left: 30px;
        //     margin-right: 30px;
        // }
      }
    }
    > li > .navItemButtons > a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .sub-menu {
    a {
      padding-top: 7px;
      padding-bottom: 7px;

      padding-left: 20px;
    }
  }
}
@media only screen and (max-width: 409.98px) {
  #iconLinks {
    margin-right: 0;
  }
  .logo {
    width: 200px;
  }
}
@media only screen and (max-width: 369.98px) {
  .logo {
    width: 180px;
  }
}
</style>
