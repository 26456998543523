//import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import { Store } from '@moods/taitaen-core'

import Layout from './components/layout/Layout'

// Public
import Login from './views/public/Login'
//import Signup from './views/public/Signup'

// Private
import TaskGallery from './views/private/task-gallery/TaskGallery'

import Diploma from './views/private/Diploma'
import Logout from './views/private/Logout'
import Home from './views/private/Home'
import Trainings from './views/private/Trainings'
import Training from './views/private/Training'
import OwnInformation from './views/private/OwnInformation'
import Help from './views/private/Help'
import TrainingExecution from './views/private/training-execution/TrainingExecution'

import MessageBoards from './views/private/message-board/Boards'
import MessageBoard from './views/private/message-board/Board'
import MessageBoardMessageList from './views/private/message-board/BoardMessageList'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    // Authenticated
    {
      path: '/',
      component: Layout,
      meta: {
        requiresAuthentication: true,
      },
      children: [
        // Login
        {
          name: 'Home',
          path: '',
          component: Home,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'Logout',
          path: '/logout',
          component: Logout,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'TaskGallery',
          path: '/task-gallery',
          component: TaskGallery,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'TaskGalleryItem',
          path: '/task-gallery/:taskSlug',
          component: TaskGallery,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'Trainings',
          path: '/trainings',
          component: Trainings,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'Training',
          path: '/training/:trainingId',
          component: Training,
          meta: {
            requiresAuthentication: true,
            isInTrainingNav: true,
          },
        },
        {
          name: 'OwnInformation',
          path: '/own-information',
          component: OwnInformation,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'Help',
          path: '/help',
          component: Help,
          meta: {
            requiresAuthentication: true,
          },
        },
        // Training container
        {
          name: 'TrainingExecution',
          path: 'training/:trainingId/course/:courseId',
          component: TrainingExecution,
          meta: {
            requiresAuthentication: true,
            isInTrainingNav: true,
          },
        },
        {
          name: 'MessageBoards',
          path: '/messageboards',
          component: MessageBoards,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'MessageBoard',
          path: '/messageboard/:messageboardId',
          component: MessageBoard,
          meta: {
            requiresAuthentication: true,
          },
        },
        {
          name: 'MessageBoardMessageList',
          path: '/messageboard-messages/:messageboardId/:topicId',
          component: MessageBoardMessageList,
          meta: {
            requiresAuthentication: true,
          },
        }
      ]
    },
    {
      name: 'Diploma',
      path: '/diploma',
      component: Diploma,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Visitors
    {
      path: '/',
      component: Layout,
      meta: {
        requiresVisitor: true,
      },
      children: [
        {
          name: 'Login',
          path: 'login',
          component: Login,
          meta: {
            requiresVisitor: true,
          }
        },
        // {
        //   name: 'Signup',
        //   path: 'signup',
        //   component: Signup,
        //   meta: {
        //     requiresVisitor: true,
        //   }
        // },
      ]
    },
    {
      path: '/*',
      redirect: '/'
    }
  ],
})

function moveToTop() {
  window.scrollTo(0, 0);
}

router.beforeEach((to, from, next) => {
  moveToTop();

  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    if (!Store.getter('User/isAuthenticated')) {
      return next({
        name: 'Login'
      })
    }
  }

  if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (Store.getter('User/isAuthenticated')) {
      return next({
        path: '/'
      })
    }
  }

  return next();
})

export default router;
