
import { ref, reactive } from 'vue'

//const orientation = ref(useWindowSize().checkOrientation());
const orientation = ref(checkOrientation());
const lastWindowSize = reactive({
  innerWidth: window.innerWidth,
  innerHeight: window.innerHeight
});

const resizeListener = reactive({
  lastInnerWidth: window.innerWidth,
  lastInnerHeight: window.innerHeight,
  resizeRatio: 0.25,
  resizeOnOrientationChange: true,
  isChanged: 1,
});

var resizeListenerUpdated = false;

window.addEventListener('resize', onResize)
function onResize() {
  detectResizeRatio();

  let _orientation = useWindowSize().checkOrientation();
  if (_orientation !== orientation.value) {
    orientation.value = _orientation;

    if (resizeListener.resizeOnOrientationChange) {
      updateResizeListenerState();
    }
  }

  lastWindowSize.innerWidth = window.innerWidth;
  lastWindowSize.innerHeight = window.innerHeight;

  resizeListenerUpdated = false;
}

function checkOrientation() {
  if (window.innerHeight > window.innerWidth) {
    return 'portrait';
  }
  return 'landscape';
}

function detectResizeRatio() {
  let baseValue = 1;
  let widthDifference = window.innerWidth / resizeListener.lastInnerWidth;
  let heightDifference = window.innerHeight / resizeListener.lastInnerHeight;

  if (widthDifference >= baseValue + resizeListener.resizeRatio || widthDifference <= baseValue - resizeListener.resizeRatio) {
    updateResizeListenerState();
  } else if (heightDifference >= baseValue + resizeListener.resizeRatio || heightDifference <= baseValue - resizeListener.resizeRatio) {
    updateResizeListenerState();
  }
}

function updateResizeListenerState() {
  if (!resizeListenerUpdated) {
    resizeListener.lastInnerWidth = window.innerWidth;
    resizeListener.lastInnerHeight = window.innerHeight;
    resizeListener.isChanged = !resizeListener.isChanged;

    resizeListenerUpdated = true;
  }
}

export function useWindowSize() {

  function getFullViewportHeightMinusHeader() {
    return window.innerHeight - 64.8;
  }

  function checkOrientation() {
    if (window.innerHeight > window.innerWidth) {
      return 'portrait';
    }
    return 'landscape';
  }

  return {
    getFullViewportHeightMinusHeader,
    checkOrientation,
    orientation,
    resizeListener,
    lastWindowSize
  }
}