<template>
  <div>
    <div id="home" ref="homeRef">
      <!-- <img id="bottomLines" :src="tenantData.url+'/viivat.svg'"> -->
      <!-- <div id="bottomLines">
        <div class="line reverse"></div>
        <div class="line reverse"></div>
      </div> -->
      <!-- <Lines id="bottomLines" :count="5" :isReversed="true"/> -->

      <!-- <div class="backgroundFilter"></div> -->
      <div class="container-fluid">
        <div id="homeContainer" class="row">
          <div class="col-12 col-md-6 col-xl-5">
            <div id="homeContent" ref="homeContentRef">
              <h1>Osaaminen tuo turvaa</h1>
              <p>
                Tervetuloa suorittamaan yrityksemme Taitaen-koulutusta. Tämä koulutus tarjoaa perustason perehdytyksen toimintatapoihimme sekä turvallisuuteen. Henkilökohtainen vastuunotto tarkoittaa
                turvallisempaa ja parempaa työpaikkaa meille kaikille. Onnistutaan yhdessä, tervetuloa!
              </p>
              <p>
                <strong>
                  Etunimi Sukunimi<br />
                  YRITYS OY<br />
                  Toimitusjohtaja
                </strong>
              </p>
            </div>
          </div>
          <div id="homeBg" class="col-12 col-md-6 col-xl-7" :style="{backgroundImage: 'url('+tenantData.url+'/johdanto.jpg)'}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useResize } from "@hooks/useResize";
//import Lines from "@components/Lines";
import tenantData from "@src/tenantData";
import useTrainings from "@hooks/useTrainings"

export default {
  name: "Home",
  components: {
    //Lines
  },
  setup() {
    const isVideoPlaying = ref(false);
    const videoRef = ref(null);
    const { trainings } = useTrainings;

    const { set } = useResize();
    const homeRef = ref(null);
    const homeContentRef = ref(null);
    set(homeRef, homeContentRef);
    const fullscreen = ref(false);
    var player;

    function videoPlayer(_player) {
      player = _player;
    }

    // function startVideo() {
    //     //isVideoPlaying.value = true;
    //     videoRef.value.style.visibility = 'visible';
    //     videoRef.value.currentTime = 0;
    //     videoRef.value.play();
    //     setHtmlElementToFullscreen(videoRef.value);
    // }

    function startVideo() {
      //isVideoPlaying.value = true;
      videoRef.value.style.visibility = "visible";

      player.play();
      player
        .requestFullscreen()
        .then(function () {})
        .catch(function () {
          // an error occurred
        });

      player.on("fullscreenchange", function (data) {
        if (!data.fullscreen) {
          videoRef.value.style.visibility = "hidden";
          player.pause();
          player.setCurrentTime(0);
        }
      });

      player.on("ended", function () {
        player.exitFullscreen();
      });

      // player.exitFullscreen().then(function() {
      //     player.getFullscreen().then(function(fullscreen) {

      //         player.pause();
      //         player.setCurrentTime(0);
      //     })
      //     // the player exits fullscreen
      // }).catch(function(error) {
      //     // an error occurred
      // });

      //options.value.autoplay = true;
      // fullscreen.value = true;

      // videoDynamicOptions.autoplay = true;
      // videoDynamicOptions.fullscreen = true;

      //videoRef.value.currentTime = 0;
      //videoRef.value.play();
      //setHtmlElementToFullscreen(videoRef.value);
    }

    return {
      homeRef,
      homeContentRef,
      videoRef,
      fullscreen: computed(() => fullscreen.value),
      videoPlayer,
      trainings: computed(() => trainings.value),

      startVideo,
      isVideoPlaying: computed(() => isVideoPlaying.value),

      tenantData
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

#video {
  visibility: hidden;
  position: absolute;
  z-index: -25;
  /*display: none;*/
  max-width: 100%;
}

.centerFlex {
  display: flex;
  justify-content: center;
}

.centeredInline {
  display: flex;
  align-items: center;

  span {
    padding-left: 10px;
  }
}

.backgroundFilter {
  position: absolute;
  background-color: var(--theme-color-second);
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.4;
}

#bottomLines {
  position: absolute;
  bottom: 0;
  width: 60%;
}

.videoButton {
  display: inline-block;
  /*text-align: center;*/
  cursor: pointer;
  margin-top: 40px;

  img {
    width: 70px;
  }
  span {
    //text-transform: uppercase;
    font-weight: bold;
  }
}

.videoButtonContainer {
  img {
    width: 100px;
  }
  span {
    text-transform: uppercase;
    font-weight: bold;
  }
}
@media only screen and (min-width: $lgMaxWidth) {
  .videoButtonContainer {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: $mdMinWidth) and (max-width: $lgMaxWidth) {
  .videoButtonContainer {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: $mdMaxWidth) {
  .videoButtonContainer {
    position: relative;
  }
}

#homeContent {
  padding-left: 30px;
  padding-right: 30px;
}

#home {
  position: relative;

  h1 {
    font-weight: bold;
  }
}
@media screen and (min-width: $smMinWidth) {
  #home {
    .container-fluid, .row {
      height: 100%;
    }
  }
  #homeContent {
    padding-top: 100px;
    //padding-bottom: 100px;
    //padding-bottom: 300px;
  }
}
#homeBg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media screen and (max-width: $smMaxWidth) {
  #homeContent {
    padding-bottom: 60px;
  }
  #homeBg {
    padding-bottom: 56.25%;
  }
  #home {
    height: auto !important;
  }
}
// #homeContent {
//     margin-top: 50px;
//     //max-width: 480px;
//     max-width: 600px;
//     padding-bottom: 30px;
// }
</style>
