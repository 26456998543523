<template>
  <div>
    <div class="header">
      <div id="diplomaLogoContainer"><img :src="tenantData.tenantLogo" alt="Logo" /></div>
    </div>
    <div class="body">
      <h1>Koulutustodistus</h1>
      <h2>Matti Meikäläinen</h2>

      <p>on suorittanut koulutuksen</p>
      <h2>Valspar Industrial Mix</h2>
      <p>30.7.2021.</p>

      Minna Meikäläinen, Koulutuspäällikkö
      <div></div>
    </div>
  </div>
</template>

<script>
//import Loading from 'Loading'
import tenantData from "@src/tenantData";

export default {
  name: 'OwnInformation',
  components: {
    //Loading
  },
  setup() {
    return {
      tenantData
    }
  }
};
</script>

<style lang="scss">
// body {
//   background-color: var(--theme-color-first);
//   font-family: "Montserrat", sans-serif;
// }
// @page {
//   size: 7in 9.25in;
//   margin: 27mm 16mm 27mm 16mm;
// }
// @media print {
  
// }
// .header {
//   position: relative;
//   display: flex;
//   justify-content: center;
//   height: 350px;

//   background-color: var(--theme-color-second);
// }
// h1 {
//   text-transform: uppercase;
// }
// .body {
//   text-align: center;
//   color: var(--theme-color-second);
//   padding-top: 80px;
// }
// #diplomaLogoContainer {
//   position: absolute;
//   bottom: 0;

//   img {
//     margin-bottom: 40px;
//   }
// }
</style>