<template>
  <div>
    <template v-if="showModal">
      <Modal class="materialModal" :closeModal="closeModal">
        <template v-slot:header></template>
        <template v-slot:body>
          <div ref="modalBodyRef">
            <template v-if="previousMaterialCourseStructures.length > 0">
              <template v-for="(materialCs, idx) in previousMaterialCourseStructures" :key="idx">
                <HandleCourseStructure :courseStructure="materialCs" />
                <hr class="separator" v-if="idx+1 !== previousMaterialCourseStructures.length"/>
              </template>
            </template>
            <!-- <HandleCourseStructure :courseStructure="previousMaterialCourseStructure" /> -->
            <button class="button raquoAfter" @click="closeModal">Sulje</button>
          </div>
        </template>
      </Modal>
    </template>

    <template v-if="isContent(courseStructure)">
      <RenderCourseStructureContent :courseStructure="courseStructure" />
    </template>
    <template v-else-if="isTask(courseStructure)">
      <RenderCourseStructureContent
        :isTest="isTest"
        :isInsideContainer="isInsideContainer"
        :courseStructure="courseStructure"
        :hasPreviousMaterial="previousMaterialCourseStructures.length > 0 ? true : false"
        :showPreviousMaterial="showPreviousMaterial"
      />

      <component
        v-if="taskComponent"
        :is="taskComponent"
        :taskId="courseStructure.structureTypeId"
        :taskType="courseStructure.structureRefData.type"
        :data="getJson(courseStructure.structureRefData.data)"
        :isTest="isTest !== 'undefined' ? isTest : false"
        :key="courseStructure.id"
      ></component>
      <template v-else>
        <strong>HUOM! Tuntematon tehtävätyyppi.</strong>
      </template>
    </template>
    <template v-else-if="isContainer(courseStructure)">
      <RenderCourseStructureContent :courseStructure="courseStructure" />

      <HandleContainer :courseStructure="courseStructure" :isTest="isTest" />
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent, shallowRef, computed, ref } from "vue";
import { CourseStructureHelper } from "@moods/taitaen-core";
import { getComponentNameByTypeId } from "@utils/Training";
import { getJson } from "@utils/General";
import Modal from "@tools/Modal";

import HandleContainer from "./HandleContainer";
import RenderCourseStructureContent from "./RenderCourseStructureContent";
import TrainingHandler from "./TrainingHandler";

export default {
  name: "HandleCourseStructure",
  components: {
    HandleContainer,
    RenderCourseStructureContent,
    Modal,
  },
  props: ["courseStructure", "isTest", "isInsideContainer"],
  setup(props) {
    const taskComponent = shallowRef(null);
    const showModal = ref(false);
    const previousMaterialCourseStructures = ref([]);

    setTaskComponent();
    updatePreviousMaterialState();

    function setTaskComponent() {
      if (
        CourseStructureHelper.isTask(props.courseStructure) &&
        !taskComponent.value
      ) {
        const taskType = getComponentNameByTypeId(
          props.courseStructure.structureRefData.type
        );
        if (taskType) {
          taskComponent.value = defineAsyncComponent(() =>
            import("./task-types/" + taskType)
          );
        } else {
          taskComponent.value = null;
        }
      }
    }

    function updatePreviousMaterialState() {
      const { course } = TrainingHandler.trainingInstance.get();
      previousMaterialCourseStructures.value = course.getPreviousMaterialCourseStructuresBeforeTasks();
    }

    function showPreviousMaterial() {
      updatePreviousMaterialState();
      showModal.value = true;
      document.body.classList.add('preventScroll')
    }

    function closeModal() {
      showModal.value = false;
      document.body.classList.remove('preventScroll')
    }

    return {
      isTask: CourseStructureHelper.isTask,
      isContent: CourseStructureHelper.isContent,
      isContainer: CourseStructureHelper.isContainer,

      taskComponent: computed(() => taskComponent.value),
      getJson,
      showPreviousMaterial,
      // previousMaterialCourseStructure: computed(
      //   () => previousMaterialCourseStructure.value
      // ),
      previousMaterialCourseStructures: computed(() => previousMaterialCourseStructures.value),
      showModal: computed(() => showModal.value),
      closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.materialModal {
  > .modalOverlay
    > .modalContent
    > .modalContentContainer
    > .modalInnerContent {
    height: 80vh;
    background-color: var(--theme-color-first);
    color: var(--theme-color-second);
    border: 0;
  }
}
.separator {
  height: 2px;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
</style>