<template>
  <div class="layoutContainer">
    <div class="subnav contentBox">
      <ul v-if="lastWindowSize.innerWidth >= 1200">
        <template v-for="(task, slug) in tasks" :key="task.type">
          <li>
            <router-link :to="{ name: 'TaskGalleryItem', params: { taskSlug: slug } }">
              {{ task.header }}
            </router-link>
          </li>
        </template>
      </ul>
      <select v-else @change="changeTask">
        <option value="" :selected="$route.params.taskSlug === undefined">-- Valitse tehtävä --</option>
        <option v-for="(task, slug) in tasks" :value="slug" :key="task.type" :selected="slug === $route.params.taskSlug">
          {{ task.header }}
        </option>
      </select>
    </div>
    <div class="contentBox">
      <TaskGalleryItem v-if="$route.params.taskSlug" :taskSlug="$route.params.taskSlug" />
      <template v-else>
        <div class="introText">
          <h1>Pelillinen oppimismetodi tehostaa muistamista</h1>
          <p>Erilaisten oppimistyylien mahdollistaminen ja koulutusten pelillisyyden lisääminen sitouttaa käyttäjää paremmin, tehostaa muistamista ja lisää tyytyväisyyttä. Taitaen-tehtävätyypeillä koulutukseen lisätään vaikuttavuutta ja nopeutetaan opitun tiedon käyttöönottoa.</p>
        </div>

        <div class="pointsContainer">
          <div class="pointBox grayBox">Info</div>
          <div class="pointArrow colorGray">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
            </svg>
          </div>
          <div class="pointBox yellowBox">Tehtävä</div>
          <div class="pointArrow colorYellow">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
            </svg>
          </div>
          <div class="pointBox grayBox">Tulos</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

import { useWindowSize } from "@hooks/useWindowSize";

import TaskGalleryItem from "./TaskGalleryItem";

import TaskGalleryData from "./TaskGalleryData";

export default {
  name: "TaskGallery",
  components: {
    TaskGalleryItem,
  },
  setup() {
    const router = useRouter();

    const { lastWindowSize } = useWindowSize();

    function changeTask(e) {
      if (e.target.value !== "") {
        router.push({ name: "TaskGalleryItem", params: { taskSlug: e.target.value } }).catch(() => {});
      } else {
        router.push({ name: "TaskGallery" }).catch(() => {});
      }
    }

    return {
      name,
      tasks: TaskGalleryData.taskListItems,
      changeTask,
      lastWindowSize,
    };
  },
};
</script>


<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

select {
  width: 100%;
  max-width: 400px;
}

.layoutContainer {
  display: flex;
}
@media only screen and (min-width: $lgMinWidth) {
  .layoutContainer {
    min-height: 600px;
  }
}

.introText {
  max-width: 350px;
}

.subnav {
  max-width: 390px;
  flex: 0 0 390px;
  background-color: var(--theme-color-fourth);

  ul {
    list-style: none;
    padding-left: 0;

    li:not(:first-child) {
      margin-top: 15px;
    }
  }

  a {
    color: #b1afaf;

    &:hover {
      color: var(--theme-color-first);
    }
  }

  .router-link-exact-active {
    color: var(--theme-color-first);
    font-weight: bold;
  }
}

@media only screen and (max-width: $lgMaxWidth) {
  .layoutContainer {
    flex-flow: column;
  }
  .subnav {
    flex: 1;
    max-width: 100%;
  }
}

.pointsContainer {
  display: flex;
}
.pointBox {
  width: 100px;
  height: 50px;
  color: var(--theme-color-first);

  display: flex;
  justify-content: center;
  align-items: center;
}
.pointArrow {
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    font-size: 30px;
  }
}
.colorGray {
  color: #bdbcbc;
}
.colorYellow {
  color: var(--theme-color-first);
}
.grayBox {
  background: #bdbcbc;
}
.yellowBox {
  background: var(--theme-color-first);
}
</style>
