<template>
  <div></div>
</template>

<script>
import { Store } from '@moods/taitaen-core'

export default {
  created() {
    // Store.dispatch('User/removeAnswers')
    //   .then(this.logoutRequest)
    //   .catch(this.logoutRequest)
    this.logoutRequest();
  },
  methods: {
    logoutRequest() {
      Store.dispatch('User/destroyToken')
        .then(this.logout)
        .catch(this.logout)
    },
    logout() {
      Store.resetState();
      this.$router.push({ name: 'Login' }).catch(err => console.error('virhe ohjauksessa', err));
    }
  }
};
</script>
