<template>
  <div id="trainingHeader" ref="trainingHeaderRef" class="container-fluid">
    <div class="row">
      <div class="col defaultBgColor">
        <!--<div class="col" :style="'background: '+training.configurations.value.color">-->
        <div class="itemHeader">
          <div class="details">
            <!--<div class="arrow rotateDeg90"></div>-->
            <span>{{ training.name }}</span>
          </div>
          <div class="result"></div>
        </div>
      </div>
      <div class="col courseDefaultBgColor">
        <!--<div class="col" :style="'background: '+course.configurations.value.color">-->
        <div class="itemHeader">
          <div class="details">
            <span>{{ course.name }}</span>
          </div>
          <div class="result"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from "vue";

import TrainingHandler from "./TrainingHandler";
import { renderCourse } from "./TrainingHandlerProgress";
import useTrainingHeaderElement from './useTrainingHeaderElement'

export default {
  components: {},
  setup() {
    const { training } = TrainingHandler.trainingInstance.get();

    const { trainingHeaderRef, scrollToTop } = useTrainingHeaderElement();

    watch(() => trainingHeaderRef.value, scrollToTop);

    //const trainingHeaderRef = ref(null);

    //watch(() => trainingHeaderRef.value, scroll);
    //watch(() => renderCourseStructure.value, scroll);
    //watch(() => renderShowResult.value, scroll);

    function getTrainingBackground() {
      return "#0066a6";
      //return ((config) ? config.themeColor : 'secondColor')
    }
    function getCourseBackground() {
      return "#009dff";
      //((course.configuration) ? course.configuration.themeColor : 'secondColor')
    }

    return {
      trainingHeaderRef,
      training: training.get(),
      course: renderCourse,
      getTrainingBackground,
      getCourseBackground,
    };
  },
};
</script>

<style lang="scss">
ul#containerGroups {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;

  li:last-child {
    margin-right: 0;
  }
  li.part {
    margin-bottom: 0;
  }
}
.itemHeader {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.defaultBgColor {
  span {
    color: var(--theme-color-second);
  }
}
.courseDefaultBgColor {
  //background-color: #b7dcf6;
  background-color: var(--theme-color-third);
}
</style>
