<template>
  <div>
    <template v-if="!loading">
      <div v-if="getCourseCompleted()" v-html="getCourseCompleted()"></div>
      <h1 v-else>Olet suorittanut kurssin onnistuneesti</h1>

      <template v-if="trainingState.trainingState === ProgressState.PASSED">
        <div v-if="getTrainingCompleted()" v-html="getTrainingCompleted()"></div>
        <h1 v-else>Olet suorittanut koulutuksen onnistuneesti</h1>

        <!-- <p><a href="#" @click.prevent="" class="raquoAfter" @click="showFeedbackForm">Anna palautetta</a></p> -->
      </template>

      <div class="juttu">
        <template v-if="nextCourse">
          <button
            class="button"
            @click="
              startLoading();
              goToNextCourse();
            "
          >
            Siirry seuraavaan kurssiin
          </button>
        </template>
        <button class="button button3" @click="restart()">Aloita kurssi alusta</button>
      </div>
      <br /><br />

      <TrainingHomeButton />
    </template>
    <Loading :loading="loading" />
    <!-- <router-link class="button2" :to="{name: 'Training', params: {trainingId: route.params.trainingId}}">
          <span class="input-group-text" id="basic-addon1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
              <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
            </svg>
          </span>
          <small>Koulutuksen etusivulle</small>
        </router-link> -->
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import TrainingHandler from "../TrainingHandler";
import { resetInternalState, updateRenderStateByCoreState } from "../TrainingHandlerProgress";
import TrainingHomeButton from "../TrainingHomeButton";
import Loading from "Loading";
import { getJson, getConfContentValue } from "@utils/General";
import { TrainingEnums, AppConfig } from "@moods/taitaen-core";

export default {
  components: {
    TrainingHomeButton,
    Loading,
  },
  setup() {
    const loading = ref(false);
    const { trainingAPI, training, course, trainingState } = TrainingHandler.trainingInstance.get();
    const route = useRoute();
    const router = useRouter();
    const { ProgressState } = TrainingEnums;

    const nextCourse = trainingAPI.getNextUncompletedCourse();

    function startLoading() {
      loading.value = true;
    }

    function getCourseCompleted() {
      const confContent = getJson(course.get().confContent);
      return getConfContentValue(confContent, "courseCompleted");
    }

    function getTrainingCompleted() {
      const confContent = getJson(training.get().confContent);
      return getConfContentValue(confContent, "trainingCompleted");
    }

    function restart() {
      let text = "Haluatko varmasti aloittaa kurssin uudelleen?";
      if (!confirm(text) === true) {
        return;
      }

      startLoading();
      resetInternalState();

      //trainingAPI.restart().then(() => {
      trainingAPI.restartCourse().then(() => {
        updateRenderStateByCoreState();
        loading.value = false;
      });

      //Store.dispatch()
      //TrainingHandler.initTrainingInstance();
    }

    function goToNextCourse() {
      router.push({ name: "TrainingExecution", params: { trainingId: training.getId(), courseId: nextCourse.courseId } });
    }

    return {
      environmentMode: AppConfig.get().environmentMode,
      getCourseCompleted,
      getTrainingCompleted,
      trainingState: trainingState.get(),
      ProgressState,
      course,
      route,
      restart,
      nextCourse,
      goToNextCourse,
      startLoading,
      loading: computed(() => loading.value),
    };
  },
};
</script>

<style lang="scss">
.juttu {
  display: flex;

  > button:not(:last-child) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 650px) {
  .juttu {
    flex-direction: column;

    > button:not(:last-child) {
      margin-right: 0px;
    }
  }
}
</style>