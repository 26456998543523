<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <h1>Omat tiedot</h1>
          <br>
          <h2>Suoritetut koulutukset</h2>
          <div v-if="loading" class="flexCenter loaderPadding">
            <Loading :loading="loading" />
          </div>
          <div v-if="!loading">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-3 diploma" v-for="(training) in trainings" :key="training.id">
                <div class="diplomaThumbnail defaultBgColor"></div>
                <br>
                {{training.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';
import { Store } from "@moods/taitaen-core";
import Loading from 'Loading'

export default {
  name: 'OwnInformation',
  components: {
    Loading
  },
  setup() {
    const loading = ref(false);
    const trainings = ref({});

    onBeforeMount(() => {
      getTrainings()
    })

    function getTrainings() {
      loading.value = true;

      Store.dispatch('Trainings/getTrainings')
      .then((res) => {
        trainings.value = res.data;
        loading.value = false;
      })
      .catch(() => {

      })
    }

    return {
      trainings: computed(() => trainings.value),
      loading: computed(() => loading.value)
    } 
  }
};
</script>

<style lang="scss">
.diplomaThumbnail {
  padding-bottom: 100%;
  border: 2px dashed var(--theme-color-second);
}
.diploma {
  margin-bottom: 30px;
}
</style>