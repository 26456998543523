<template>
  <footer>
    <template v-if="isAuthenticated">
      <div class="container-fluid">
        <div class="row">
          <div class="contentBox col-12 col-lg-6">
            <p class="footerlinks">
              <router-link class="raquoAfter" :to="{ name: 'Home' }">Etusivu</router-link>
              <br />
              <!-- <router-link class="raquoAfter" to="#">Lähteet</router-link><br>
                            <router-link class="raquoAfter" to="#">Tietosuojaseloste</router-link><br>
              <router-link class="raquoAfter" to="#">Turvallisuushavaintolomake</router-link><br>-->
            </p>

            <p>2020 &copy; Moods Oy</p>
          </div>
          <div class="col-12 col-lg-6">
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 contentBox">
            <router-link class="raquo" :to="{ name: 'Home' }">Etusivu</router-link>
            <br />
            <!--<router-link class="raquo" :to="{name: 'PrivacyPolicy'}">Tietosuojaseloste</router-link><br>-->
            <p>2020 &copy; Moods Oy</p>
          </div>
        </div>
      </div>
    </template>
  </footer>
</template>

<script>
//import StatusesList from '@components/login/StatusesList'
import { Store } from "@moods/taitaen-core";

export default {
  name: "Footer",
  components: {
    //StatusesList,
  },
  computed: {
    isAuthenticated() {
      //if (this.$store.getters['user/isAuthenticated']) {
      if (Store.getter("User/isAuthenticated")) {
        return true;
      }
      return false;
      //return this.$store.getters['user/isAuthenticated']
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

footer {
  background-color: var(--footer-bg-color);

  padding-top: 50px;
  padding-bottom: 10px;
}
@media only screen and (min-width: $mdMinWidth) {
  .statusesList {
    margin-left: auto;
  }
}
@media only screen and (max-width: 600px) {
  .statusesList {
    flex-flow: column;
  }
}
</style>
