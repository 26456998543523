<template>
  <div class="messages" :class="[isSuccess ? 'successMessages' : 'errorMessages']" v-if="isNotEmpty(messages)">
    <template v-if="typeof messages === 'object'">
      <template v-if="messages.__v_isVNode === true">
        <component :is="messages" />
      </template>
      <template v-else>
        <template v-for="item in messages">
          <template v-if="Array.isArray(item)">
            <div v-for="message in item" :key="message">{{ message }}</div>
          </template>
        </template>
      </template>
    </template>
    <template v-else-if="typeof messages === 'string'">
      <div :key="messages">{{ messages }}</div>
    </template>
  </div>
</template>

<script>
import { isNotEmpty } from "@utils/General.js";

export default {
  name: "Messages",
  props: ["isSuccess", "messages"],
  methods: {
    isNotEmpty: isNotEmpty,
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

.errorMessages, .successMessages {
  border: 1px solid var(--theme-color-second);
  background-color: var(--theme-color-first);
  padding: 10px 20px;
}
.errorMessages {
  color: var(--error-color);
}
.successMessages {
  color: var(--theme-color-second);
}
</style>