<template>
  <div id="result">
    <template v-if="showCourseResult">
    <!-- <template v-if="renderCourseState.courseState.state === ProgressState.PASSED"> -->
      <CourseResult />
    </template>
    <template v-else>
      <TaskResult />
    </template>
  </div>
</template>

<script>
//import { TrainingEnums } from "@moods/taitaen-core";

import TaskResult from "./TaskResult";
import CourseResult from "./CourseResult";
import { showCourseResult, renderCourse, renderCourseState } from "../TrainingHandlerProgress";

export default {
  components: {
    CourseResult,
    TaskResult,
  },
  setup() {
    //const { ProgressState } = TrainingEnums;

    return {
      //ProgressState,
      showCourseResult,
      renderCourse,
      renderCourseState,
    };
  },
};
</script>
